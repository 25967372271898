body {
  margin: 0;
  xbackground-color: lightblue;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

header {
  border-bottom: 1px solid grey;
  background-color: lightblue;
}

.Levelcont {
  border: 2px solid gray;
}

h1 {
  margin: 0;
  padding: 10px;
}

hr {
  border: 1px solid grey;
  border-radius: 1px;
}

button:focus:not(.focus-visible),
input:focus:not(.focus-visible),
select:focus:not(.focus-visible),
textarea:focus:not(.focus-visible) {
  outline: none;
}

.margin {
  margin: 10px;
}

button {
  padding: 10px;
  background: none;
  border-radius: 5px;
  font-size: 1.2em;
  letter-spacing: 0.3px;
  font-weight: 500;
  margin: 5px;
}

.Level005cover {
  width: 100px;
  height: 50px;
  display: inline-block;
}

button[data-switch="true"] {
  background-color: #ff3a00;
}

button {
  background-color: #d81111;
  color: white;
}

button:disabled {
  background-color: #c4c4c4;
  color: #000000;
}

button:disabled[data-switch="true"] {
  background-color: #885a4c;
  color: rgb(205, 205, 205);
}

button[data-white="true"] {
  background-color: #ffffff;
  color: black;
}

.Level008spinner {
  xbackground-color: #d81111;
  height: 250px;
  xwidth: 250px;
  animation-name: spin;
  animation-duration: 1250ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.Level008button {
  animation-name: oppspin;
  animation-duration: 1250ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.small-icon {
  width: 75px;
  height: 75px;
}

.Level010hr {
  width: 400px;
}

.Level010text {
  margin: 100px;
}

.long-button {
  padding: 10px 150px 10px 150px;
}

.Level010checkboxcont input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.Level010checkboxcont input:checked ~ .Level010span {
  background-color: #3050ef;
  border: none;
  content: "⅃";
}

.Level010checkboxcont input:checked ~ .Level010span::after {
  display: inherit;
  content: "⅃";
  color: white;
  font-weight: 100;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 5px;
}

.Level010span {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #c1c1c1;
}

.Level010checkboxcont {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Level {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.level011text {
  margin: 50px;
  text-align: left;
}

.Level011middlecont {
  display: flex;
  flex-direction: row;
  xbackground-color: red;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes oppspin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
